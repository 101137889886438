<template>
  <v-container fluid class="pa-8">
    <v-row class="pb-4">
      <GsNavButtons />
    </v-row>
    <v-form
      class="mb-6"
      style="width: 100%"
      v-model="valid"
      ref="form"
      v-if="resourceServer"
    >
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('name')"
            v-model="resourceServer.resourceName"
            :rules="formRules.resourceName"
            :autofocus="true"
          />
          <v-text-field
            :label="$t('code')"
            v-model="resourceServer.resourceCode"
            :rules="formRules.resourceCode"
          />
          <v-text-field
            :label="$t('resourceOrigin')"
            v-model="resourceServer.resourceOrigin"
            :rules="formRules.resourceOrigin"
          />
          <v-text-field
            :label="$t('logoUri')"
            v-model="resourceServer.logoUri"
            :rules="formRules.logoUri"
          />
          <v-select
            :label="$t('scope')"
            v-model="resourceServer.scope"
            :items="scopes"
            :menu-props="{ maxHeight: '400' }"
            multiple
          />
          <v-select
            :label="$t('resourceScope')"
            v-model="resourceServer.resourceScope"
            :items="scopes"
            :menu-props="{ maxHeight: '400' }"
          />
          <v-select
            :label="$t('resourceClient')"
            v-model="resourceServer.resourceClient"
            :items="clients"
            :menu-props="{ maxHeight: '400' }"
          />
          <GsIdentitySelector
            :label="$t('identity')"
            v-model="resourceServer.identityId"
            :rules="formRules.identityId"
            :idTypes="['resource']"
          />
          <v-select
            :label="$t('clusterCode')"
            v-model="resourceServer.clusterCode"
            :rules="formRules.clusterCode"
            :items="$store.state.lookups.clusterCodes"
            :menu-props="{ maxHeight: '400' }"
          />
        </v-col>
      </v-row>
      <!-- errors -->
      <div class="d-flex flex-row" v-if="error">
        <span class="subtitle-1 mb-4 red--text"
          >{{ $t("form_invalid_text") }}: {{ error }}</span
        >
      </div>

      <!-- <actions> -->
      <div class="d-flex flex-row">
        <v-spacer />
        <!-- <v-btn class="ma-2" color="default" @click="cancel_click">
          {{ $t("cancel") }}
        </v-btn> -->
        <v-btn
          class="ma-2"
          color="primary"
          @click="save_click"
          :loading="loading"
        >
          {{ $t("save") }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsIdentitySelector from "@/components/GsIdentitySelector";
const fieldValidators = require("@/managers/fieldValidators");
import GsNavButtons from "@/components/GsNavButtons";

export default {
  name: "GsResourceServerEditor",
  components: {
    GsIdentitySelector,
    GsNavButtons
  },
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      mode: null,
      resourceServer: null,
      formRules: {
        resourceName: [fieldValidators.requiredString],
        resourceCode: [fieldValidators.requiredString],
        resourceOrigin: [fieldValidators.requiredUri],
        logoUri: [fieldValidators.optionalUri],
        scope: [fieldValidators.requiredArray],
        resourceScope: [fieldValidators.optionalUri],
        resourceClient: [fieldValidators.optionalUri],
        identityId: [fieldValidators.requiredString],
        clusterCode: [fieldValidators.requiredString]
      },
      valid: true,
      error: null,
      loading: false,
      scopes: [],
      clients: []
    };
  },
  created: async function() {
    this.getScopes();
    this.getClients();
  },
  watch: {
    "$route.params.resourceServerId": {
      immediate: true,
      handler(v) {
        if (v === "new") {
          this.mode = "create";
          this.createResourceServer();
          return;
        }
        this.mode = "edit";
        this.init();
        this.loadResourceServer(v);
      }
    }
  },
  async mounted() {},
  methods: {
    async init() {
      await this.setBreadCrumbs();
    },
    createResourceServer() {
      this.resourceServer = {
        resourceServerId: null,
        resourceName: null,
        resourceCode: null,
        resourceOrigin: null,
        logoUri: null,
        scope: null,
        resourceScope: null,
        resourceClient: null,
        identityId: null,
        clusterCode: null
      };
    },
    async loadResourceServer(resourceServerId) {
      const url = `${this.$store.state.idp_api_url}/resource-servers/${resourceServerId}`;
      const method = "GET";
      try {
        const response = await callAPI({ url, method });
        this.resourceServer = response.data;
        // console.log(this.resourceServer)
      } catch (err) {
        console.log(err);
      }
    },
    async cancel_click() {
      this.$router.go(-1);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/resource-servers`;
      if (this.mode == "edit")
        url += `/${this.resourceServer.resourceServerId}`;

      const method = this.mode == "create" ? "POST" : "PATCH";
      let data = {
        resourceName: this.resourceServer.resourceName,
        resourceCode: this.resourceServer.resourceCode,
        resourceOrigin: this.resourceServer.resourceOrigin,
        scope: this.resourceServer.scope,
        identityId: this.resourceServer.identityId,
        clusterCode: this.resourceServer.clusterCode
      };

      if (this.resourceServer.logoUri)
        data.logoUri = this.resourceServer.logoUri;

      if (this.resourceServer.resourceScope)
        data.resourceScope = this.resourceServer.resourceScope;

      if (this.resourceServer.resourceClient)
        data.resourceClient = this.resourceServer.resourceClient;

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        // console.log(response.status)
        if (response.status == 204 || response.status == 200) {
          this.$router.go(-1);
        }

        // this.client = response.data
        // console.log(this.client)
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        }
      } finally {
        this.loading = false;
      }
    },
    async getScopes() {
      const result = await callAPI({
        url: this.$store.state.idp_api_url + "/scopes?limit=1000",
        method: "GET"
      });
      this.scopes = result.data.map(p => ({
        value: p._uri,
        text: p.scopeName
      }));
    },
    async getClients() {
      const result = await callAPI({
        url: this.$store.state.idp_api_url + "/clients?limit=1000",
        method: "GET"
      });
      this.clients = result.data.map(p => ({
        value: p._uri,
        text: p.client_name
      }));
    }
  }
};
</script>
